
/* SignUpSplit.css */
/* @TODO: This is mostly copied from ESG signup page. We should refactor this to be a shared component. */
#icp4 .splitContainer {
    display: flex;
    min-height: 100vh;
    background-color: #f7f9fc;
    overflow: hidden; /* Prevent scrollbars if any content overflows slightly */
}

/* Left side: Attractive gradient, branding, marketing info */
#icp4 .splitLeft {
    flex: 1;
    position: relative;
    background: linear-gradient(135deg, #480971 0%, #f4e8f6 100%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem;
    color: #fff;
}

/* Optional overlay if you want to dim or lighten the background further
   for improved text contrast. Uncomment if needed.

.splitLeft::before {
  content: '';
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.2);
  pointer-events: none;
}
*/

#icp4 .branding {
    position: relative; /* so it layers above the optional overlay */
    text-align: center;
    max-width: 500px;
    margin: 0 auto;
}

#icp4 .branding-variant2 {
    margin: 0 auto;
    max-width: 500px;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.4); /* a translucent overlay for readability */
    padding: 2rem;
    border-radius: 8px;
}

#icp4 .logo {
    display: inline-block;
    margin-bottom: 2rem;
    font-size: 1.5rem;
    font-weight: 700;
    letter-spacing: 1px;
}

#icp4 .branding h1 {
    font-size: 2.2rem;
    font-weight: 700;
    margin-bottom: 1rem;
    line-height: 1.2;
}

#icp4 .branding p {
    font-size: 1.05rem;
    line-height: 1.6;
    margin: 0;
}

/* Right side: Sign-up form */
#icp4 .splitRight {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
}

#icp4 .formContainer {
    width: 100%;
    max-width: 450px;
    background-color: #fff;
    padding: 2rem 2.5rem;
    border-radius: 8px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
    animation: fadeInUp 0.5s ease; /* Subtle animation on load */
}

#icp4 .formHeader {
    text-align: center;
    margin-bottom: 1.5rem;
}

#icp4 .formHeader h2 {
    margin: 0;
    font-size: 1.8rem;
    color: #333;
    font-weight: 700;
}

#icp4 .formHeader p {
    font-size: 1rem;
    color: #666;
    margin-top: 0.5rem;
}

/* Spacing for each form field */
#icp4 .field {
    margin-top: 1.2rem;
}

/* Button styles */
#icp4 .submitButton {
    margin-top: 1.8rem !important;
    font-weight: 400;
    /*text-transform: none;*/
    background-color: #480971 !important; /* Force your gradient color for brand consistency */
}

/* Button hover effect */
#icp4 .submitButton:hover {
    background-color: #350657 !important;
}

#icp4 .submitButton:disabled {
    background-color: darkgray !important;
}

/* Keyframes for fade-in animation */
@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Responsive breakpoint for smaller screens */
@media screen and (max-width: 768px) {
    #icp4 .splitContainer {
        flex-direction: column;
    }

    #icp4 .splitLeft, .splitRight {
        flex: none;
        width: 100%;
        min-height: 50vh;
    }

    /* Make the gradient more centered for mobile */
    #icp4 .splitLeft {
        padding: 2.5rem 2rem;
        background-position: center;
    }
}

#icp4 .ui.button {
    width: 100%;
    padding: 0.875rem !important;
    font-size: 1rem !important;
    border-radius: 8px !important;
    background: linear-gradient(135deg, #480971 0%, #6435C9 100%) !important;
    transition: all 0.2s ease !important;
    box-shadow: 0 4px 12px rgba(100, 53, 201, 0.2) !important;
}

#icp4 .ui.button:hover {
    transform: translateY(-1px);
    box-shadow: 0 6px 16px rgba(100, 53, 201, 0.25) !important;
    background: linear-gradient(135deg, #5a0b8a 0%, #7442d9 100%) !important;
}

#icp4 .ui.button:active {
    transform: translateY(0);
}

#icp4 .subscription-agreement {
    /* background: #2A2A2A; */
    background: #F7FAFC;
    border-radius: 12px;
    padding: 2rem;
    margin: 2rem 0;
    border: 1px solid #E2E8F0;
}

#icp4 .subscription-agreement h3 {
    color: #480971;
    font-size: 1.1rem;
    margin-bottom: 1rem;
    font-weight: 600;
}

#icp4 .agreement-text {
    color: #4A5568;
    font-size: 0.9rem;
    line-height: 1.6;
}

#icp4 .agreement-text p {
    margin-bottom: 0.75rem;
}

#icp4 .agreement-text ul {
    margin: 0.75rem 0;
    padding-left: 1.25rem;
}

#icp4 .agreement-text li {
    margin-bottom: 0.5rem;
    position: relative;
}

#icp4 .agreement-text li:last-child {
    margin-bottom: 0;
}


@media (max-width: 768px) {
    #icp4 .subscription-agreement {
        padding: 1.5rem;
    }
}

#icp4 .service-fees-table {
    width: 100%;
    margin-top: 1rem;
}

#icp4 .service-fees-table table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #E2E8F0;
}

#icp4 .service-fees-table th,
#icp4 .service-fees-table td {
    padding: 1rem;
    text-align: left;
    border: 1px solid #E2E8F0;
    color: #2D3748;
}

#icp4 .service-fees-table th {
    background: #F0F4F8;
    font-weight: 600;
    color: #480971;
}

#icp4 .service-fees-table .amount-column,
#icp4 .service-fees-table .billing-column {
    text-align: right;
    width: 120px;
}

#icp4 .service-name {
    color: #2D3748;
    font-weight: 600;
    margin-bottom: 0.5rem;
}

#icp4 .service-features {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

#icp4 .feature {
    color: #4A5568;
    font-size: 0.9rem;
    display: flex;
    align-items: center;
}

#icp4 .total-row {
    background: #F0F4F8;
    font-weight: 600;
}

#icp4 .total-row td {
    color: #2D3748;
}

#icp4 .consent-checkbox {
    margin-top: 2rem !important;
}

#icp4 .consent-checkbox label {
    color: #2D3748 !important;
    font-size: 0.95rem !important;
}

#icp4 .order-form-intro {
    color: #2D3748;
    font-size: 0.95rem;
    line-height: 1.6;
    margin-bottom: 1.5rem;
}

#icp4 .order-form-intro a {
    color: #6435C9;
    text-decoration: none;
    transition: color 0.2s ease;
}

#icp4 .order-form-intro a:hover {
    color: #480971;
}

#icp4 .highlight {
    color: #6435C9;
}

#icp4 .section-title {
    color: #480971;
    font-size: 1.25rem;
    margin: 2rem 0 1rem;
    font-weight: 600;
    border-bottom: 1px solid #E2E8F0;
    padding-bottom: 0.5rem;
}

@media (max-width: 768px) {
    #icp4 .service-fees-table th,
    #icp4 .service-fees-table td {
        padding: 0.75rem;
    }

    #icp4 .amount-column,
    #icp4 .billing-column {
        width: auto;
    }
}

#icp4 .splitLeft {
    flex: 1;
    padding: 3rem;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
}

#icp4 .splitRight {
    flex: 1;
    padding: 2rem;
    margin-left: 40%;
    width: 60%;
    min-height: 100vh;
    background: white;
}


@media (max-width: 768px) {
    #icp4 .splitContainer {
        flex-direction: column;
    }

    #icp4 .splitLeft {
        position: relative;
        width: 100%;
        padding: 2rem;
        min-height: 400px;
    }

    #icp4 .splitRight {
        margin-left: 0;
        width: 100%;
        min-height: auto;
    }
} 