.LineItemsStoreTable .row-header {
  font-weight: bold;
  border-top: 2px solid #ccc;
    text-transform: capitalize;
}

.LineItemsStoreTable .row-section-closing {
    font-weight: bold;
    border-bottom: 2px solid #ccc;
    text-transform: capitalize;
}


.LineItemsStoreTable .row-subtotal {
  font-style: italic;
  background-color: #f8f5f5;
}

.LineItemsStoreTable .row-total {
  font-style: italic;
  background-color: #eee;
  font-weight: bold;
  border-top: 2px solid #ccc;
}

.LineItemsStoreTable .row-with-separator-after {
    border-bottom: 2px solid #ccc;
}

#ps-platform .LineItemsStoreTable .row-alert-failed {
    background-color: #ffe0e0;
    font-weight: bold;
    border-top: 2px solid #ccc;
}

#ps-platform .LineItemsStoreTable .row-alert-success {
    background-color: #dcffdc;
    font-weight: bold;
    border-top: 2px solid #ccc;
}

#ps-platform .LineItemsStoreTable .row-alert-children {
    background-color: #fff6db;
    font-weight: bold;
    border-top: 2px solid #ccc;
}

#ps-platform .LineItemsStoreTable.hidden-header .MuiDataGrid-columnHeaders {
    display: none;
}

.LineItemsStoreTable.hidden-header .MuiDataGrid-columnHeaders {
    display: none;
}

#ps-platform .LineItemsStoreTable .row-parent {
    font-weight: bold;
    font-size: 16px;
}

#ps-platform .LineItemsStoreTable .projections {
    border-bottom: 2px solid #bcd85f;
}

#ps-platform .LineItemsStoreTable .aggregate-column-header {
    font-weight: bold !important;
}

#ps-platform  .highlight-cell {
    background-color: #ffffcc;
}

#ps-platform  .acceptable-value-cell {
    color: green;
}

#ps-platform  .unacceptable-value-cell {
    color: orange;
}